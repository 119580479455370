import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import ReactHtmlParser from "html-react-parser"

const PostPage = ({ data }) => {
  return (
    <Layout>
      <div className="post-page">
        <div className="entry-contents">
          <div className="entry-header">
            <h1 className="entry-title">{data.strapiPost.title}</h1>
          </div>

          <div className="post-contents">
            {ReactHtmlParser(
              data.strapiPost.childStrapiPostBodyTextnode?.body ?? ""
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const Head = ({ data }) => <Seo title={data.strapiPost.title} />
export default PostPage

export const query = graphql`
  query ($slug: String) {
    strapiPost(slug: { eq: $slug }) {
      title
      publishedAt
      slug
      childStrapiPostBodyTextnode {
        body
      }
    }
  }
`
